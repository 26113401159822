/* header.css */

.header {
    background-color: black; 
    color: white;
    padding: 20px; 
    text-align: center; 
    font-size: 24px; 
    position: absolute;
    width: 100%; 
    top: 0;
    z-index: 999; 
}


.header img {
    display: flex;
    margin: 10px; /* Remove default margins */
    padding: 10px; /* Remove default padding */
    width: 200px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    position: absolute; /* Position the image */
}

.introduction {
    margin-top: 0; /* Remove margin at the top */
    padding-top: 20px; /* Add padding at the top */
    text-align: center; /* Align text in the center */
    font-size: 18px; /* Set font size */
    position: absolute; /* Position the introduction section */
    top: 20px; /* Adjust the top position */
    left: 50%; /* Align to the center */
    transform: translateX(-50%); /* Center horizontally */
}
